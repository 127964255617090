<template lang="">
  <div class="expansion-container">
    <v-expansion-panels class="expansion-card-admin-user-list">
      <v-expansion-panel>
        <v-expansion-panel-title class="title-container">
          <div class="user-list-header">
            <v-icon class="mr-2" color="white" size="x-small">mdi-account-group</v-icon>
            Users
          </div>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <AddUserModal 
            :studiesList="studiesList"
            :users="users_list"
            :regionsList="regionsList"
            @newUserData="newUser"
          />
          <br/>
          <div v-if="users.length">
            <v-data-table
              :headers="headers"
              :items="users"
              class="custom-table"
              >
                <template v-slot:[`item.action`]="{ item }">
                  <v-btn
                    small
                    fab
                    class="mr-1 ml-2"
                    color="#14C5E0"
                    title="Update user data"
                    @click="getUserChange(item)"
                  >
                    <v-icon color="white">mdi-pencil</v-icon>
                  </v-btn>
                  <v-btn
                    :disabled="item.role == 'Admin'"
                    small
                    fab
                    class="mr-1 ml-2"
                    color="error"
                    dark
                    title="Remove user"
                    @click="deleteUser(item.id, item.email)"
                  >
                    <v-icon>mdi-trash-can</v-icon>
                  </v-btn>
                </template>
            </v-data-table>
            <DeleteUserModal 
              :deleteDialog="deleteDialog"
              @update:deleteDialog="deleteDialog = $event"
              @cancel="deleteDialog = false"
              @confirm="deleteDialog = false"
              :users_list="users_list"
              :currentUser="currentUser"
              :currentId="currentId"
              @userList="checkUserList"
            />
            <EditUserModal 
              :updateDialog="updateDialog"
              @cancel="updateDialog = false"
              @confirmEdit="updateDialog = false"
              :studiesList="studiesList"
              :userSelected="userSelected"
              :users_list="users_list"
              :currentId="currentId"
              :regionsList="regionsList"
              @updateUsers="userChangedList"
              @userChange="getUserChange"
              @updateList="checkUserUpdate"
            />
          </div>
          <div v-else>No users</div>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
  import Cookies from 'js-cookie';
  import { userServices } from '../services/userService';
  import AddUserModal from '../components/AddUserModal.vue';
  import DeleteUserModal from '../components/DeleteUserModal.vue';
  import EditUserModal from '../components/EditUserModal.vue';

  export default {
    name: 'AdminUserList',
    props: {
      studiesList: {
        type: Array,
        required: true,
        default: () => [],
      },
    },
    components: {
      AddUserModal,
      DeleteUserModal,
      EditUserModal,
    },
    data() {
      return {
        headers: [
          { title: 'Email ', value: 'email', sortable: true },
          { title: 'First Name', value: 'first_name' },
          { title: 'Last Name', value: 'last_name' },
          { title: 'Company', value: 'company' },
          { title: 'Company role', value: 'company_role' },
          { title: 'Study', value: 'clean_study_name' },
          { title: 'Role', value: 'role' },
          { title: 'Region', value: 'region' },
          { title: 'Zip', value: 'zip' },
          { text: 'Actions', value: 'action', sortable: false },
        ],
        users: [],
        currentId: null,
        currentUser: '',
        deleteDialog: false,
        users_list: [],
        authorization: Cookies.get('Authorization'),
        study: Cookies.get('study'),
        regionsList: [
          'Andalucía', 
          'Aragón',
          'Asturias',
          'Canarias',
          'Cantabria',
          'Castilla-La Mancha',
          'Castilla y León', 'Cataluña',
          'Comunidad Valenciana', 'Extremadura', 'Galicia',
          'Islas Baleares', 'La Rioja', 'Madrid', 'Murcia',
          'Navarra', 'País Vasco',
        ],
        userSelected: {},
        updateDialog: false,
        localStudiesList: []
      };
    },
    watch: {
      studiesList: {
        handler(newValue) {
          this.localStudiesList = newValue
        },
        immediate: true,
        deep: true,
      }
    },
    mounted() {
      this.getUsersList();
    },
    methods: {
      async getUsersList() {
        userServices.getUsersList(this.authorization, this.study)
          .then((response) => {
            this.users = response.data
              .filter(item => item.role !== "Superadmin")
              .map(item => ({
                id: item.id,
                email: item.email,
                first_name: item.first_name,
                last_name: item.last_name,
                company: item.company,
                company_role: item.company_role,
                study: item.study,
                studies: item.studies,
                clean_study_name: item.study[0],
                role: item.role,
                region: item.region,
                zip: item.zip
              }));
          })
          .catch((err) => console.error(err));
      },
      async checkUserList(userList) {
        this.users = userList;
      },
      deleteUser(userId, userEmail) {
        this.deleteDialog = true;
        this.currentUser = userEmail;
        this.currentId = userId;
        this.users_list = this.users;
      },
      newUser() {
        this.getUsersList();
      },
      getUserChange(userData) {
        userServices.getUserChange(this.authorization, userData)
          .then(() => {
            this.updateDialog = true;
            this.users_list = this.users;
            this.userSelected = userData;
            this.currentId = userData.id;
            this.userSelected.studies = userData.studies.map((id, index) => ({
              name: userData.study[index],
              id,
            }));
            this.userSelected.regions = [userData.region];
          })
          .catch((error) => console.error(error));
      },
      checkUserUpdate() {
        setTimeout(() => {}, 2000);
      },
      userChangedList() {
        this.getUsersList();
        this.userSelected = {};
      },
    },
  };
</script>
<style lang="scss" scoped>
  .title-container {
    font-size: 18px !important;
    font-family: 'Poppins', sans-serif;
    background: linear-gradient(90deg, #6E57F8 0%, #14C5E0 100%);
    border-radius: 8px 8px 0 0;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  .user-list-header {
    margin: 0 5px;
    color: #FFFFFF;
    font-size: 1.2em;
  }

  .expansion-card-admin-user-list {
    border-radius: 16px !important;
    margin-top: 32px;
    width: 100%;
  }
  
  .expansion-container {
    display: flex;
    justify-content: center;
  }
</style>
