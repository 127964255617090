<template>
  <div>
    <v-card align="left">
      <v-container fluid class="document-information-style">
        <v-row>
          <AlertNotification v-if="alert" :text="error" :type="alertType" />
          <v-col cols="12" class="card-title-custom">
            <v-toolbar-title class="mt-3">Information</v-toolbar-title>
          </v-col>
          <v-col cols="12">
            <hr class="divider">
          </v-col>
        </v-row>

        <v-row class="document-info">
          <v-col cols="12">
            <strong>Title:</strong>
          </v-col>
          <v-col cols="12">
            {{ documentInfo.title }}
          </v-col>
        </v-row>
        <v-row class="document-info">
          <v-col cols="12">
            <strong>Created by:</strong>
          </v-col>
          <v-col cols="12">
            {{ documentInfo.user }}
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <strong>Date / Time availability range:</strong>
          </v-col>

          <!-- Row para el rol "Updater" -->
          <v-row v-if="userRole === 'Updater'" class="datepicker-information">
            <v-col cols="12" md="5">
              <v-menu
                v-model="menuStartDate" :close-on-content-click="false"
                content-class="custom-overlay--top" transition="scale-transition">
                <template #activator="{ props }">
                  <v-text-field
                    class="p-calendar"
                    v-model="formattedStartDateTime"
                    label="Start Date"
                    prepend-icon="mdi-calendar"
                    v-bind="props"
                    color="#6E57F8"
                    outlined
                    required
                    :disabled="!isEditing"
                  ></v-text-field>
                </template>
                <v-card class="custom-calendar-card">
                  <p-calendar
                    v-model="startDateTime"
                    showTime inline
                    hourFormat="24"
                    dateFormat="yy-mm-dd"
                    class="calendar-custom">
                </p-calendar>
                  <v-card-actions class="custom-card-action">
                    <v-btn text color="primary" @click="menuStartDate = false">OK</v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-col>
            <v-col cols="12" md="5">
              <v-menu
                v-model="menuEndDate" :close-on-content-click="false"
                content-class="custom-overlay--top" transition="scale-transition">
                <template #activator="{ props }">
                  <v-text-field
                    v-model="formattedEndDateTime"
                    label="End Date"
                    prepend-icon="mdi-calendar"
                    v-bind="props"
                    color="#6E57F8"
                    outlined
                    required
                    :disabled="!isEditing"
                  ></v-text-field>
                </template>
                <v-card class="custom-calendar-card">
                  <p-calendar
                    v-model="endDateTime"
                    showTime
                    inline
                    hourFormat="24"
                    dateFormat="yyyy-mm-dd"
                    class="calendar-custom">
                  </p-calendar>
                  <v-card-actions  class="custom-card-action">
                    <v-btn text color="primary" @click="menuEndDate = false">OK</v-btn>
                  </v-card-actions>
                </v-card>
              </v-menu>
            </v-col>
            <v-col cols="12" md="2" class="buttons-actions-informations">
              <!-- Botón de edición -->
              <v-btn icon
                v-if="!isEditing" @click="showConfirmButton()"
                title="Update"
                class="icon-only-btn"
              >
                <v-icon color="blue">mdi-pencil</v-icon>
              </v-btn>
              <!-- Botón de confirmación -->
              <v-btn icon
                v-else
                @click="editFile(documentInfo.internal_id)"
                title="Confirm"
                class="icon-only-btn"
              >
                <v-icon color="green">mdi-check</v-icon>
              </v-btn>
              <!-- Botón de remover -->
              <v-btn icon
                v-if="isEditing"
                @click="removeDates(documentInfo.internal_id)"
                :disabled="removeButtonDisabled"
                title="Remove dates"
                class="icon-only-btn"
              >
                <v-icon color="red">mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <!-- rol Updater end -->

          <!-- Row para el rol "Viewer" -->
          <v-row v-else class="datepicker-information">
            <v-col cols="12" md="5">
              <v-menu v-model="menuStartDate" disabled>
                <template #activator="{ props }">
                  <v-text-field
                    class="p-calendar"
                    v-model="formattedStartDateTime"
                    label="Start Date"
                    prepend-icon="mdi-calendar"
                    v-bind="props"
                    disabled
                  ></v-text-field>
                </template>
              </v-menu>
            </v-col>
            <v-col cols="12" md="5">
              <v-menu v-model="menuEndDate" disabled>
                <template #activator="{ props }">
                  <v-text-field
                    v-model="formattedEndDateTime"
                    label="End Date"
                    prepend-icon="mdi-calendar"
                    v-bind="props"
                    disabled
                  ></v-text-field>
                </template>
              </v-menu>
            </v-col>
            <!-- No buttons for the viewer role -->
          </v-row>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import { fileServices } from '../services/fileServices';
import DatePicker from 'primevue/datepicker';
import AlertNotification from '../components/AlertNotification.vue';


export default {
  name: 'DocumentInfo',
  props: {
    documentInfo: Object,
    userRole: {
      type: String,
      required: true,
    },
  },
  components: {
    AlertNotification,
    'p-calendar': DatePicker
  },
  data() {
    return {
      isEditing: false,
      startDateTime: null,
      endDateTime: null,
      menuStartDate: false,
      menuEndDate: false,
      error: '',
      alert: false,
      alertType: '',
      authorization: Cookies.get('Authorization'),
      removeButtonDisabled: false,
    };
  },
  computed: {
    formattedStartDateTime() {
      return this.startDateTime ? this.formatDate(this.startDateTime) : '';
    },
    formattedEndDateTime() {
      return this.endDateTime ? this.formatDate(this.endDateTime) : '';
    },
  },
  async mounted() {
    if (this.documentInfo) {
      this.startDateTime = this.documentInfo.start_visualization_date;
      this.endDateTime = this.documentInfo.end_visualization_date;
    }
  },

  methods: {
    handleClickOutside(type) {
      if (type === 'start') {
        this.menuStart = false;
      } else if (type === 'end') {
        this.menuEnd = false;
      }
    },
    closeMenu(type) {
      this.handleClickOutside(type);
    },
    editFile(internalId) {
      const data = {
        start_visualization_date: this.startDateTime,
        end_visualization_date: this.endDateTime,
      };
      fileServices.updateFile(this.authorization, internalId, data)
        .then((response) => {
          this.startDateTime = response.data.start_visualization_date;
          this.endDateTime = response.data.end_visualization_date;
          this.showAlert('Dates updated correctly', 'success');
          if (this.removeButtonDisabled) {
            this.removeButtonDisabled = false;
          }
          this.isEditing = false;
        })
        .catch((error) => {
          if (error && error.data && error.data.non_field_errors) {
            this.showErrorAlert(error.data.non_field_errors[0])
          } else {
            this.showErrorAlert('Error trying to modify dates');
          }
        });
    },
    removeDates(internalId) {
      if (this.removeButtonDisabled) return;
      this.removeButtonDisabled = true;
      const data = { start_visualization_date: null, end_visualization_date: null };
      fileServices.updateFile(this.authorization, internalId, data)
        .then((response) => {
          this.startDateTime = response.data.start_visualization_date;
          this.endDateTime = response.data.end_visualization_date;
          this.isEditing = false;
          this.showAlert('Dates removed correctly', 'success');
        })
        .finally(() => {
          this.$nextTick(() => {
            setTimeout(() => {
              this.removeButtonDisabled = false; // Volver a habilitar el botón después de 10 segundos
            }, 10000);
          });
        });
    },
    formatDate(date) {
      return date ? new Date(date).toLocaleString() : '';
    },
    showAlert(message, type) {
      this.alertType = type;
      this.error = message;
      this.alert = true;
      this.$nextTick(() => {
        setTimeout(() => {
          this.alertType = '';
          this.alert = false;
        }, 4000);
      });
    },
    showErrorAlert(message) {
      this.showAlert(message, 'error');
    },
    showConfirmButton() {
      this.isEditing = true;
    },
  },
};
</script>

<style lang="scss" scoped>

  .v-expansion-panel--active {
    border-radius: 16px !important;
  }
  .datetime-field {
    margin: 2px;
    max-width: 200px;
  }
  .buttons-actions-informations {
    display: flex;
    align-items: center;
    margin-top: -30px;
    margin-left: -17px;
    justify-content: flex-start;
  }
  .icon-only-btn {
    background-color: transparent !important; /* Quita el fondo del botón */
    box-shadow: none !important; /* Elimina cualquier sombra */
    border: none !important; /* Elimina cualquier borde */
    padding: 0 !important; /* Ajusta el padding para que se vea solo el ícono */
    min-width: auto !important; /* Ajusta el ancho mínimo */
  }

  .icon-only-btn:hover {
    background-color: transparent !important; /* Mantén el fondo transparente al pasar el ratón */
  }

  .icon-only-btn:disabled {
    opacity: 0.5; /* Ajusta la opacidad para el estado deshabilitado */
  }

  .buttons-actions-informations v-btn {
    margin: 2px;
  }
  .datepicker-information {
    margin-left: 5px;
  }
  .data-information {
    margin-left: 8px;
    margin-top: -15px;
  }
  .document-info-item {
    margin-left: 10px;
    margin-top: -8px;
  }
  .p-datepicker {
    max-width: 100% !important;
    margin-left: 8px !important;
    margin-bottom: 2px !important;
  }

.custom-calendar-card {
  max-width: 380px;
  margin-left: 4px;
  padding: 5px;
}

.custom-card-action {
  display: flex;
  justify-content: flex-end;
}

.calendar-custom {
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
}

.p-component {
  margin: 10px;
}
.p-datepicker {
  width: 100%; /* Ajusta el ancho del calendario */
}

.p-datepicker-inline {
  display: block; /* Forza el modo inline a ser más legible */
}

.p-datepicker .p-datepicker-header {
  padding: 10px 16px; /* Ajusta el padding en el encabezado */
}

.p-datepicker .p-datepicker-time-panel {
  padding: 8px 16px; /* Ajusta el padding para la sección de tiempo */
}
</style>
