<template lang="">
  <div class="expansion-container">
    <AlertNotification v-if="alert" :text="error" :type="alertType" />
    <v-dialog
      id="edit-study-modal-dialog"
      v-model="updateStudyDialog"
      persistent
      max-width="800px"
      class="rounded-xl"
    >
      <v-card id="edit-study-modal-card-dialog" class="title pb-1 font-weight-black">
        <v-card-title class="card-title-custom" primary-title>
          <span id="text-edit-study-dialog" class="white--text text-h5">Study</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <div class="info-row">
                  <strong class="left-text">Study: </strong> <span class="right-text">{{ studySelected.name }}</span>
                </div>
              </v-col>
              <v-col cols="12">
                <div class="info-row">
                  <strong class="left-text">Acronym: </strong><span class="right-text">{{ studySelected.acronym }}</span>
                </div>
              </v-col>
              <v-col cols="12">
                <strong class="left-text">Date / Time availability range:</strong>
              </v-col>
              
              <!-- Fechas -->
              <v-col cols="12" md="5">
                <v-menu
                  v-model="menuStartDate"
                  :close-on-content-click="false"
                  content-class="custom-overlay--top"
                  transition="scale-transition"
                >
                  <template #activator="{ props }">
                    <v-text-field
                      class="p-calendar"
                      v-model="formattedStartDateTime"
                      label="Start Date"
                      prepend-icon="mdi-calendar"
                      v-bind="props"
                      color="#6E57F8"
                      outlined
                      required
                      :disabled="!isEditing"
                    ></v-text-field>
                  </template>
                  <v-card class="custom-calendar-card">
                    <p-calendar
                      v-model="startDateTime"
                      showTime
                      inline
                      hourFormat="24"
                      dateFormat="yy-mm-dd"
                      class="calendar-custom"
                    ></p-calendar>
                    <v-card-actions class="custom-card-action">
                      <v-btn text color="primary" @click="closeMenu('start')">OK</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </v-col>
              <v-col cols="12" md="5">
                <v-menu
                  v-model="menuEndDate"
                  :close-on-content-click="false"
                  content-class="custom-overlay--top"
                  transition="scale-transition"
                >
                  <template #activator="{ props }">
                    <v-text-field
                      v-model="formattedEndDateTime"
                      label="End Date"
                      prepend-icon="mdi-calendar"
                      v-bind="props"
                      color="#6E57F8"
                      outlined
                      required
                      :disabled="!isEditing"
                    ></v-text-field>
                  </template>
                  <v-card class="custom-calendar-card">
                    <p-calendar
                      v-model="endDateTime"
                      showTime
                      inline
                      hourFormat="24"
                      dateFormat="yyyy-mm-dd"
                      class="calendar-custom"
                    ></p-calendar>
                    <v-card-actions class="custom-card-action">
                      <v-btn text color="primary" @click="closeMenu('end')">OK</v-btn>
                    </v-card-actions>
                  </v-card>
                </v-menu>
              </v-col>
              <v-col cols="12" md="2">
                <!-- Botón de edición -->
                <v-btn
                  icon
                  v-if="!isEditing"
                  @click="isEditing = true"
                  title="Update"
                  class="icon-only-btn"
                >
                  <v-icon color="blue">mdi-pencil</v-icon>
                </v-btn>
                <!-- Botón de cancelar edición de fechas -->
                <v-btn
                  icon
                  v-else
                  @click="isEditing = false"
                  title="Cancel Edit"
                  class="icon-only-btn"
                >
                  <v-icon color="red">mdi-close</v-icon>
                </v-btn>
              </v-col>
              <!-- termina fechas -->
              <v-col cols="12">
                <span id="text-delete-dialog" class="warning-text">
                  <strong>Warning: </strong>Changing the date range of this study will automatically adjust both the start
                    and end dates/times of availability for any associated folders and documents within
                    those folders if they extend beyond the new study range. This adjustment ensures that
                    all associated data aligns with the updated study timeline. Please confirm to proceed
                    with these comprehensive adjustments.
                </span>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="close-edit-btn"
            class="btn-cancel"
            text
            @click="updateStudyDialog = false"
          >
            Close
          </v-btn>
          <v-btn
            id="save-edit-btn"
            elevated
            :disabled="!isEditing"
            class="btn-accept"
            @click="updateStudy(studySelected.internal_id)"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-expansion-panels class="expansion-card-admin-study-information">
      <v-expansion-panel>
        <v-expansion-panel-title class="title-container">
          <div class="study-modal-header">
            <v-icon class="mr-2" color="white" size="x-small">mdi-book-open-outline</v-icon>
            Study
          </div>
        </v-expansion-panel-title>
        <v-expansion-panel-text>
          <div v-if="studies.length">
            <table class="custom-table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Acronym</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="study in studies" :key="study.internal_id">
                  <td>{{ study.name }}</td>
                  <td>{{ study.acronym }}</td>
                  <td>{{ formatDate(study.start_visualization_date) }}</td>
                  <td>{{ formatDate(study.end_visualization_date) }}</td>
                  <td class="action-btns">
                    <v-btn
                      small
                      fab
                      class="mr-1 ml-2"
                      color="#14C5E0"
                      title="Update study data"
                      @click="showModalForUpdateStudy(study)"
                    >
                      <v-icon color="white">mdi-pencil</v-icon>
                    </v-btn>
                    <v-btn
                      :disabled="true"
                      class="mr-1 ml-2"
                      color="error"
                    >
                      <v-icon>mdi-trash-can</v-icon>
                    </v-btn>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div v-else>No Studies</div>
        </v-expansion-panel-text>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import { studyServices } from '../services/studyService';
import DatePicker from 'primevue/datepicker';
import AlertNotification from '../components/AlertNotification.vue';

export default {
  name: 'AdminStudyInformation',
  props: {
    studies: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  components: {
    AlertNotification,
    'p-calendar': DatePicker,
  },
  data() {
    return {
      authorization: Cookies.get('Authorization'),
      updateStudyDialog: false,
      isEditing: false,
      studySelected: {},
      menuStartDate: false,
      menuEndDate: false,
      startDateTime: null,
      endDateTime: null,
      error: '',
      alert: false,
      alertType: '',
    };
  },
  computed: {
    formattedStartDateTime() {
      return this.startDateTime ? this.formatDate(this.startDateTime) : '';
    },
    formattedEndDateTime() {
      return this.endDateTime ? this.formatDate(this.endDateTime) : '';
    },
  },
  methods: {
    handleClickOutside(type) {
      if (type === 'start') {
        this.menuStartDate = false;
      } else if (type === 'end') {
        this.menuEndDate = false;
      }
    },
    closeMenu(type) {
      this.handleClickOutside(type);
    },
    showModalForUpdateStudy(study) {
      this.updateStudyDialog = true;
      this.studySelected = study;

      // Inicializar las fechas en el modal
      this.startDateTime = study.start_visualization_date;
      this.endDateTime = study.end_visualization_date;
      this.isEditing = false;
    },
    async updateStudy(internalId) {
      const data = {
        start_visualization_date: this.startDateTime,
        end_visualization_date: this.endDateTime,
      };
      try {
        const response = await studyServices.update(this.authorization, internalId, data);
        
        // Actualizar las fechas en el estudio seleccionado
        this.studySelected.start_visualization_date = response.data.start_visualization_date;
        this.studySelected.end_visualization_date = response.data.end_visualization_date;

        this.showAlert('Study dates updated correctly', 'success');

        this.isEditing = false;
        this.updateStudyDialog = false;
      } catch (error) {
        if (error && error.data && error.data.non_field_errors) {
          this.showErrorAlert(error.data.non_field_errors[0])
        } else {
          this.showErrorAlert('Error trying to modify study dates');
        }
      }
    },
    formatDate(date) {
      return date ? new Date(date).toLocaleString() : '';
    },
    showAlert(message, type) {
      this.alertType = type;
      this.error = message;
      this.alert = true;
      this.$nextTick(() => {
        setTimeout(() => {
          this.alertType = '';
          this.alert = false;
        }, 4000);
      });
    },
    showErrorAlert(message) {
      this.showAlert(message, 'error');
    },
  },
};
</script>

<style lang="scss" scoped>
  .action-btns {
    text-align: center;
  }
  .datetime-field {
    margin: 2px;
    max-width: 200px;
  }
  .calendar-custom {
    width: 100%;
    padding-left: 10px;
    padding-right: 10px;
  }
  .title-container {
    font-size: 18px !important;
    font-family: 'Poppins', sans-serif;
    background: linear-gradient(90deg, #6E57F8 0%, #14C5E0 100%);
    border-radius: 8px 8px 0 0; /* Redondeo solo en las esquinas superiores */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  }

  .study-modal-header {
    font-family: 'Poppins', sans-serif;
    margin-right: 5px;
    margin-left: 5px;
    color: #FFFFFF;
    font-size: 1.2em;
  }

  .expansion-card-admin-study-information {
    border-radius: 16px !important;
    width: 100%;
    margin-top: 32px;
  }

  .expansion-container {
    display: flex;
    justify-content: center !important;
    font-family: 'Poppins', sans-serif;
  }

  .custom-table {
    width: 100%;
    border-collapse: collapse;
  }

  .custom-table th, .custom-table td {
    border: 1px solid #ddd;
    padding: 8px;
  }

  .custom-table th {
    background-color: #f2f2f2;
    text-align: left;
  }
  .btn-accept {
    background-color: #6E57F8 !important;
    color: white !important;
  }
  .btn-cancel {
    color: #6E57F8 !important;
  }
  .icon-only-btn {
    background-color: transparent !important;
    box-shadow: none !important;
    border: none !important;
    padding: 0 !important;
    min-width: auto !important;
  }
  .info-row {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .left-text {
    text-decoration: underline;
    font-weight: 550;
    font-family: 'Poppins', sans-serif;
  }

  .right-text {
    text-align: right;
    margin-right: 40px;
    font-family: 'Poppins', sans-serif;
  }
  .warning-text {
    color: red;
    display: block;
    text-align: center;
  }  
</style>
