<template>
  <div>
    <v-card>
      <v-container fluid class="document-information-style">
        <GenericDeleteModal
          :showModal="deleteFileConfirmModal"
          :onSuccess="handleDeleteComment"
          :currentId="currentId"
          @close="deleteFileConfirmModal = false" />
        <v-row>
          <v-col cols="12" class="card-title-custom">
            <v-toolbar-title class="mt-3">
              Comments
              <v-icon>mdi-comment-outline</v-icon>
            </v-toolbar-title>
          </v-col>
          <v-col cols="12">
            <hr class="divider">
          </v-col>
        </v-row>

        <v-row class="comments-container">
          <v-col v-if="comments.length" cols="12">
            <div v-for="comment in comments" :key="comment.id">
              <div class="comment" :id="comment.id">
                <strong>{{ comment.created_by_user_text }}</strong>
                <span class="pl-2" v-if="comment.time_from_creation">
                  ({{ comment.time_from_creation }})
                </span>

                <v-btn
                  v-if="comment.is_comment_owner"
                  id="delete-comment-btn"
                  color="black"
                  variant="plain"
                  elevation="0"
                  @click="deleteComment(comment.id)"
                  class="delete"
                >
                  <v-icon>mdi-delete-outline</v-icon>
                </v-btn>

                <div>
                  {{ comment.text }}
                </div>
              </div>
              <v-divider></v-divider>
            </div>
          </v-col>
          <v-col v-else-if="comments.length == 0 && documentLoadedCorrectly" cols="12">
            No comments
          </v-col>
          <v-col v-else class="load-items" cols="12">
            <CircularLoader :showLoading="!documentLoadedCorrectly" width="5" size="50" />
          </v-col>
          <v-dialog
            id="add-user-modal-dialog"
            v-model:modelValue="dialogAddComment"
            persistent
            max-width="600px"
          >
            <template #activator="{ props }">
              <div class="mx-auto pb-2">
                <v-btn
                  id="add-comment-btn"
                  color="#6E57F8"
                  text-color="white"
                  dark
                  center
                  v-bind="props"
                  :disabled="!documentLoadedCorrectly"
                  @click="dialogAddComment = true; commentText = ''"
                  class="mx-auto custom-add-comment-btn"
                >
                  Comment
                </v-btn>
              </div>
            </template>

            <v-card id="add-comment-card-dialog" class="comments-container">
              <v-card-title class="card-title-custom">
                <span id="add-comment-span-dialog" class="text-h5 white--text title-text-comments">
                  New Comment
                </span>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-textarea
                      v-model="commentText"
                      label="Comment Text"
                      class="text-area-custom-font"
                      variant="outlined"
                      color="#6E57F8"
                      required
                      auto-grow
                    ></v-textarea>
                  </v-row>
                </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  class="btn-cancel"
                  text
                  @click="dialogAddComment = false"
                >
                  Close
                </v-btn>
                <v-btn
                  elevated
                  class="btn-accept"
                  :disabled="!commentText"
                  @click="saveNewComment"
                >
                  Save
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-row>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import Cookies from 'js-cookie';
import moment from 'moment';
import { commentServices } from '../services/commentService';
import GenericDeleteModal from "../components/GenericDeleteModal.vue";
import CircularLoader from "../components/CircularLoader.vue";

moment.locale('es');

export default {
  name: 'DocumentComments',
  components: {
    GenericDeleteModal,
    CircularLoader
  },
  props: {
    documentId: String,
    documentLoadedCorrectly: Boolean
  },
  data() {
    return {
      comments: [],
      deleteFileConfirmModal: false,
      currentUser: Cookies.get('user'),
      dialogAddComment: false,
      commentText: '',
      currentId: null,
      authorization: Cookies.get('Authorization'),
    };
  },
  async mounted() {
    if (this.documentLoadedCorrectly) {
      await this.getComments();
    }
  },
  watch: {
    // Watcher para detectar cambios en documentLoadedCorrectly
    async documentLoadedCorrectly(newVal) {
      if (newVal) {
        await this.getComments();
      }
    },
  },
  methods: {
    async getComments() {
      try {
        const response = await commentServices.listByDocumentId(
          this.authorization, this.documentId,
        );
        const comments = response.data || [];
        const formattedComments = comments.map((comment) => ({
          ...comment,
          created_by_user_text: comment.user.email,
          time_from_creation: moment(comment.timestamp).fromNow(),
          is_comment_owner: this.currentUser === comment.user.email,
        }));
        this.comments = formattedComments.length ? formattedComments : [];
      } catch (error) {
        // eslint-disable-next-line no-console
        console.error('Error fetching comments:', error);
        this.comments = [];
      }
    },
    async deleteComment(commentId) {
      this.currentId = commentId
      this.deleteFileConfirmModal = true;
    },
    async handleDeleteComment() {
      try {
        await commentServices.delete(this.authorization, this.currentId);
        this.currentId = null;
        await this.getComments();
      } catch (e) {
        // eslint-disable-next-line no-console
        console.error(e);
      }
      this.deleteFileConfirmModal = false;
    },
    async saveNewComment() {
      const newComment = { document_id: this.documentId, text: this.commentText };
      await commentServices.create(this.authorization, newComment);
      await this.getComments();
      this.dialogAddComment = false;
    },
  },
};
</script>

<style lang="scss" scoped>
  .comment {
    border-radius: 15px;
    border-style: solid;
    border-width: 1.5px;
    padding: 5px;
    margin-bottom: 5px;
  }
  .btn-accept {
    background-color: #6E57F8 !important;
    color: white !important;
  }
  .btn-cancel {
    color: #6E57F8 !important;
  }
  .comments-container {
    padding-bottom: 10px;
  }

  .title-text-comments {
    font-family: 'Poppins', sans-serif;
  }
  .load-items {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%; /* Ajusta la altura según sea necesario */
  }
</style>
