<template>
  <v-row>
    <!-- Diálogo para agregar usuario -->
    <v-dialog
      id="add-user-modal-dialog"
      v-model="showAddUserDialog"
      persistent
      max-width="600px"
      class="rounded-xl"
    >
      <template v-slot:activator="{ attrs }">
        <v-row>
          <v-col cols="12" class="d-flex justify-end btn-container">
          <v-spacer></v-spacer>
          <v-btn size="x-small"
            id="add-user-btn"
            color="#6E57F8"
            v-bind="attrs"
            class="white--text add-user-btn"
            @click="showAddUserDialog = true">
              <v-icon  class="icon-circle mr-2">mdi-plus</v-icon>
              <span class="add-btn-text mr-2 ml-2">Add User</span>
          </v-btn>
        </v-col>
      </v-row>
      </template>

      <!-- Contenido del diálogo -->
      <v-card id="add-user-card-dialog" class="add-user-profile">
        <v-card-title class="user-profile-title">
          <span id="add-user-span-dialog" class="white--text text-h5">User Profile</span>
        </v-card-title>
        <v-card-text>
          <!-- Formulario de usuario -->
          <v-container>
            <v-row>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  id="add-user-email"
                  v-model="email"
                  data-test="AddUserModal"
                  label="Email*"
                  :rules="emailRules"
                  color="#6E57F8"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  id="add-user-first-name"
                  v-model="firstName"
                  label="First name"
                  :rules="requiredFieldRules"
                  color="#6E57F8"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  id="add-user-last-name"
                  v-model="lastName"
                  label="Last name*"
                  :rules="requiredFieldRules"
                  color="#6E57F8"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="company"
                  label="Company*"
                  color="#6E57F8"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  v-model="companyRole"
                  label="Company role*"
                  color="#6E57F8"
                  persistent-hint
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-text-field
                  id="add-user-password"
                  v-model="pass"
                  label="Password*"
                  type="password"
                  color="#6E57F8"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-autocomplete
                  :items="studiesList"
                  v-model="studyName"
                  item-title="name"
                  item-text="id"
                  label="Study*"
                  return-object
                  color="#6E57F8"
                  item-color="#6E57F8"
                  multiple
                  required
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" sm="6" md="6">
                <v-select
                  v-model="roleAssigned"
                  :items="availableRoles"
                  label="Role*"
                  :rules="roleRules"
                  color="#6E57F8"
                  item-color="#6E57F8"
                  required
                ></v-select>
              </v-col>
              <v-col cols="6" md="6">
                <v-autocomplete
                  :items="regionsList"
                  v-model="region"
                  label="Region"
                  :rules="requiredFieldRules"
                  persistent-hint
                  color="#6E57F8"
                  item-color="#6E57F8"
                  required
                ></v-autocomplete>
              </v-col>
              <v-col cols="6" md="6">
                <v-text-field
                  v-model="zip"
                  label="Zip"
                  :rules="requiredFieldRules"
                  persistent-hint
                  color="#6E57F8"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
          <small>*indicates required field</small>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            id="close-adduser-btn"
            class="btn-cancel"
            text
            @click="showAddUserDialog = false"
          >
            Close
          </v-btn>
          <v-btn
            id="save-adduser-btn"
            elevated
            class="btn-accept"
            data-test="saveButton"
            @click="createUser"
            :disabled="formValid"
          >
            Save
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Cookies from 'js-cookie';
import { userServices } from '../services/userService';

export default {
  name: 'AddUserModal',
  data() {
    return {
      showAddUserDialog: false,
      items: [],
      userCreated: {},
      roleAssigned: ' ',
      firstName: '',
      lastName: '',
      email: '',
      pass: '',
      studyId: [],
      studyName: [],
      availableRoles: ['Admin', 'Viewer', 'Updater'],
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      requiredFieldRules: [(v) => !!v || 'This field is required',],
      roleRules: [(v) => !!v || 'Role is required'],
      availableStudies: [],
      studiesAssigned: [],
      authorization: Cookies.get('Authorization'),
      role: Cookies.get('role'),
      companyRole: '',
      company: '',
      region: [],
      zip: '',
    };
  },
  props: {
    studiesList: Array,
    users: Array,
    regionsList: Array,
  },
  created() {
    this.checkSuperadmin();
  },
  computed: {
    formValid() {
      let disabledBtn = !(
        this.email &&
        this.firstName &&
        this.lastName &&
        this.pass &&
        this.roleAssigned &&
        this.region &&
        this.studyId &&
        this.zip
      )
      return disabledBtn
    },
  },
  methods: {
    checkSuperadmin() {
      if (this.role === 'Superadmin') {
        this.availableRoles.push('Superadmin');
      }
    },
    createUser() {
      this.userCreated.first_name = this.firstName;
      this.userCreated.last_name = this.lastName;
      this.userCreated.email = this.email;
      this.userCreated.password = this.pass;
      this.userCreated.study = this.studyName.map((study) => study.name);
      this.userCreated.studyId = this.studyName.map((study) => study.id);
      this.userCreated.company_role = this.companyRole;
      this.userCreated.company = this.company;
      this.userCreated.region = this.region;
      this.userCreated.zip = this.zip;
      this.userCreated.role = this.roleAssigned;
      userServices.createUser(this.authorization, this.userCreated)
        .then((response) => {
          const newUserList = {
            first_name: this.userCreated.first_name,
            last_name: this.userCreated.last_name,
            email: this.userCreated.email,
            role: this.userCreated.role,
            study: this.userCreated.study,
            study_id: this.userCreated.studyId,
            company_role: this.userCreated.company_role,
            company: this.userCreated.company,
            region: this.userCreated.region,
            zip: this.userCreated.zip,
            id: response.data.id,
          };
          this.showAddUserDialog = false; // Cerrar el diálogo después de guardar
          this.$emit('newUserData', newUserList);
          // Limpiar los campos
          this.resetFields();
        })
        .catch((error) => {
          // eslint-disable-next-line no-console
          console.error(error);
        });
    },
    resetFields() {
      this.email = '';
      this.firstName = '';
      this.lastName = '';
      this.pass = '';
      this.roleAssigned = '';
      this.studyName = [];
      this.studyId = [];
      this.companyRole = '';
      this.company = '';
      this.region = '';
      this.zip = '';
    },
  },
};
</script>

<style scoped>
  .add-user-profile {
    font-family: 'Poppins', sans-serif;
  }
  .add-btn-text {
    font-family: 'Poppins', sans-serif;
    font-size: 10px;
    font-weight: 550;
  }
  .add-user-btn {
    background-color: #6E57F8;
    height: 42px !important;
    width: 140px;
    font-family: 'Poppins', sans-serif;
    display: flex;
    align-items: center; 
  }
  .btn-container {
    display: flex;
    justify-content: flex-end; /* Alinear a la derecha */
    margin-bottom: 10px;
    margin-top: 18px;
    padding-right: 24px; /* Añade un pequeño padding si lo deseas */
  }
  .user-profile-title {
    font-weight: bold;
    color: white;
    text-align: left;
    background: linear-gradient(
      90deg,
      #6E57F8 0%,
      #14C5E0 100%
    ) !important;
  }
  .btn-accept {
    background-color: #6E57F8 !important;
    color: white !important;
  }
  .btn-cancel {
    color: #6E57F8 !important;
  }
  .icon-circle {
    color: white; /* Color del ícono */
    border: 2px solid white; /* Borde blanco alrededor del ícono */
    border-radius: 50%; /* Hacer el borde redondeado */
    padding: 5px; /* Espacio alrededor del ícono para el borde */
    width: 24px; /* Ajustar tamaño del círculo */
    height: 24px; /* Ajustar tamaño del círculo */
    display: flex;
    justify-content: center;
    align-items: center;
  }
</style>
